<template>
	<main v-if="visible">
		<el-dialog :append-to-body="true" :visible="true" @close="close" @closed="closed" >
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="选择配件" prop="attachment_id" >
						<el-select class="el-block" v-model="form.attachment_id" multiple>
							<el-option v-for="(item,index) in attachment_option" :key="index" :label="item.title" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="发布状态" prop="status">
							<el-switch v-model="form.status" active-text="正常" inactive-text="草稿"></el-switch>
						</el-form-item>
						<el-form-item label="权重" prop="sort">
							<el-input v-model="form.sort" type="number"></el-input>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="发布时间" prop="create_time">
							<el-date-picker v-model="form.create_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
						<el-form-item label="下架时间" prop="end_time">
							<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {product, product_attachment as api} from "@/api/admin";
import _ from "lodash";
import {timeToString,stringToTime} from "@/service/admin/common";
export default {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
			params_field:[
				{prop:"参数名",field:"title",type:"input"},
				{prop:"参数值",field:"value",type:"input"},
			],
			attachment_option:[]
		}
	},
	computed:{
		tag_list(){
			let tag_list = [];
			if(this.form.category_id){
				const category = this.column_option.category.find((item,index)=>{
					return item.id === this.form.category_id
				})
				tag_list = category?.tag?.split(',') || [];
			}
			return tag_list;
		},
		rules(){
			return {
				category_id:[
					{required:true,message:"请选择分类"}
				],
				type:[
					{required:true,message:"请选择类型"}
				],
				title:[
					{required:true,message:"请输入标题"}
				],
			}
		}
	},
	methods:{
		createParams(){
			const params = [];
			if(this.form.category_id){
				const category = this.column_option.category.find((item,index)=>{
					return item.id === this.form.category_id
				})
				const thumb = category?.params?.split(',') || [];
				thumb.map((item,index)=>{
					params.push({
						title:item,
						value:""
					})
				})
			}
			this.$set(this.form,'params',JSON.stringify(params))
		},
		getDetail(){
			api.get({
				id:this.query.id
			}).then((res)=>{
				const data = res.detail;
				this.form = this.decodeData(data);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data = this.encodeData(data);
				data.product_id = this.query.product_id;

				this.submit_loading = true;
				api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		decodeData(data){
			data.status = data.status === 1;
			data.create_time = timeToString(data.create_time);
			data.end_time = timeToString(data.end_time);
			data.attachment_id = data.attachment_id?.split(',') || [];
			return data;
		},
		encodeData(data){
			data.status = data.status ? 1 : 2;
			data.create_time = stringToTime(data.create_time);
			data.end_time = stringToTime(data.end_time);
			data.attachment_id = data.attachment_id?.join(',') || '';
			return data;
		},
		init(query = {}){
			this.query = query;
			this.getAttachment();
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
			this.visible = true;
		},
		getAttachment(){
			product.all({type:2,limit:999}).then((res)=>{
				this.attachment_option = res.list;
			})
		},
		close(){
			this.visible = false;
		},
		closed(){
			this.form = {};
			this.$refs['formRef'].resetFields();
		}
	}
}
</script>
