<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{ title }}</div>
			</template>
			<div class="el-content">
				<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
				<div class="action-box">
					<div class="action">
						<el-button class="el-icon-refresh" @click="all"></el-button>
						<el-button class="el-icon-plus" @click="create">创建</el-button>
						<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
							<template #reference>
								<el-button icon="el-icon-delete">删除</el-button>
							</template>
						</el-popconfirm>
						<template v-if="import_visible">
							<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
							<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
								<input type="file" id="file" @change="uploadFile" accept=".xlsx">
							</el-button>
						</template>
					</div>
					<div class="other">
						<table-column :column="column" @change="changeColumn"/>
						<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
					</div>
				</div>
				<div class="table-box">
					<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
						<template #column="{scope,item}"></template>
						<template #action="{scope}">
							<div class="el-link-group">
								<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
									<template #reference>
										<el-link type="primary" >删除</el-link>
									</template>
								</el-popconfirm>
							</div>
						</template>
					</table-table>
				</div>
				<div class="page-box">
					<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
				</div>
			</div>
			<components-create ref="createRef" :title="title" :column_option="column_option" @success="all"/>
			<table-import ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		</el-dialog>
	</main>
</template>
<script>
import {product_attachment as api} from "@/api/admin";
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixin";

export default {
	components:{ComponentsCreate},
	mixins:[MixinIndex],
	data(){
		return {
			visible:false,
			title:"关联配件",
			column_option: {
				status: [{id: 1, title: "正常", type: "success"}, {id: 2, title: "草稿", type: "danger"}],
				type:[{id:1,title:"产品"},{id:2,title:"配件"}],
				category:[]
			},
			import_visible:false,
			export_visible:false,
		}
	},
	computed:{
		import_field(){
			return []
		},
		export_field(){
			return []
		},
		column(){
			return [
				{label:"ID",prop:"id",width: 100,sortable:true},
				{label:"产品名称",prop:"product_title"},
				{label:"配件名称",prop:"title"},
				{label:"权重",prop:"sort",type:"sort"},
				{label:"状态",prop:"status",type: "option",search:true, option:this.column_option.status},
				{label:"发布时间",prop:"create_time",type:'datetime',sortable:true},
				{label:"下架时间",prop:"end_time",type:'datetime',sortable:true},
				{label:"操作",prop:"action",fixed:'right',type:'action'},
			]
		}
	},
	methods:{
		create(){
			this.$refs['createRef'].init({
				product_id:this.query.id
			});
		},
		close(){
			this.visible = false;
		},
		init(query = {}){
			this.query = query;
			this.visible = true;
			this.all();
		},
		privateAll(params){
			params.product_id = this.query.id;
			return api.all(params);
		},
		privateDeleted(row){
			return api.delete({
				id:row.id
			})
		},
		privateExportAll(params){
			return api.all(params)
		},
		privateSaveAll(list){
			return api.import(list)
		},
		privateChangeSort(data){
			return api.changeOrder(data)
		},
	}
}
</script>
<style lang="scss" scoped>
.el-content{min-height: 0;}
</style>
